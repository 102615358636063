<template>
  <div>
    <v-container fluid>
      <v-row justify="center" align="center">
        <v-col cols="12">
          <Subheader icon="mdi-tools" title="Support-Tools"></Subheader>
          <div class="mt-7">
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <CodeDisplay :items="sources"></CodeDisplay>
              </v-col>
              <slot name="insideRow"> </slot>
            </v-row>
          </div>
        </v-col>
        <slot name="outsideRow"> </slot>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Subheader from "@/components/_systemwide/Subheader.vue";
import CodeDisplay from "@/components/_system/helpers/CodeDisplay.vue";
export default {
  name: "support-tools",
  props: {
    sources: {
      type: Array, // [{ title: String, data: this.data }]
      required: true,
    },
  },
  components: {
    Subheader,
    CodeDisplay,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
